<template>
    <v-container v-if="!item || !item.id">
        <div class="mt-1x">
            <p-breadcrumbs page="profile.control-panel.project.view" />
        </div>
        <p>Sem elemento para visualizar</p>
    </v-container>
    <v-container class="mx-xl-4 px-xl-4" v-else>
        <div class="mx-xl-4 px-xl-4">
            <div  class="mx-xl-4 px-xl-4">
                <div class="mt-1x">
                    <p-breadcrumbs page="profile.control-panel.project.view" />
                </div>
                <div class="mt-1x"> 
                    <Banner v-if="item.src" :src="item.banner" :clipped="false" height="330px" />
                </div>
                <v-row class="mt-3x">
                    <v-col cols="12">
                        <h1 class="headline primary--text">{{ item.title }}</h1>
                        <p>{{ item.paisProducaoDescricao }} {{item.paisProducaoDescricao && item.dataProducaoFmt ? ' • ' : null}} {{ item.dataProducaoFmt }}</p>
                    </v-col>
                </v-row>
                <v-sheet  v-if="item.externalLink"  class="mt-2x secondary" elevation="8">
                        <VideoPlayer 
                        
                            :key="item.id" 
                            :src="item.externalLink" 
                            :poster="item.src" 
                            height="600px" max-height="600px"/>
                        
                </v-sheet>
                <div v-html="item.description"></div>

                <p class="h4 text-uppercase primary--text mt-2x">Sinopse</p>
                <div v-html="item.sinopse"></div>

                <p class="h4 text-uppercase primary--text mt-2x">Bio</p>
                <div v-html="item.bio"></div>

                <div class="mt-2x">
                    <p class="h4 text-uppercase text-primary">Créditos</p>
                    <div class="ml-10"> 
                        <v-row v-if="item.credits && item.credits.length != 0">
                            <v-col v-for="credit in item.credits" :key="credit.id"
                                cols="12" md="4">
                                <span><font-awesome-icon icon="circle" size="xs" class="text-primary"/>  <span> {{ credit.name }}</span></span>
                            </v-col>
                        </v-row>
                        <p v-else> - - - - - </p>
                    </div>
                </div>
                <div class="mt-2x">
                    <p class="h4 text-uppercase text-primary">Prémios/Nomeações</p>
                    <div class="ml-10">
                        <v-row  v-if="item.awards && item.awards.length != 0">
                            <v-col v-for="award in item.awards" :key="award.id"
                                cols="12" md="4">
                                <span><font-awesome-icon icon="award" size="2x" class="text-primary"/>  <span> {{ award.name }}</span></span>
                            </v-col>
                        </v-row>
                        <p v-else> - - - - - </p>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>
<script>
import Banner from '../../Banner';
import VideoPlayer from '../../VideoPlayer';
export default {
    props: ['item'],
    components: {
        Banner,
        VideoPlayer,
    }
}
</script>