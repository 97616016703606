<template>
    <ProjectDetail v-if="!loading" :item="project" />
</template>
<script>
import ProjectDetail from '../../../components/author/control-panel/ProjectDetail';
import { mapState, mapActions } from 'vuex';
import {HOME} from '../../../routes';
export default {
    components: {
        ProjectDetail,
    },
    data() {
        return {
            
        }
    },
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('mySpace/project', ['project', 'loading'])
    },
    methods: {
        ...mapActions({
            detail: 'mySpace/project/detail',
        }),
        fetchDetail() {
            this.detail({id: this.id,});
        },
    },
}
</script>